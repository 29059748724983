import { createGlobalStyle } from 'styled-components'

import font1 from '../assets/fonts/MochaFrappuccino-Regular.woff'
import font2 from '../assets/fonts/SourceCodePro-Regular.woff'

const Typography = createGlobalStyle`

    @font-face {
        font-family: MochaFrappuccino;
        src: url(${font1});
        
    }

    @font-face {
        font-family: SourceCode;
        src: url(${font2});
    }

    html {
        font-family: SourceCode;
        font-size: clamp(.8rem, -0.8750rem + 2.3333vw, 1.1rem);
        letter-spacing: .05em;
    }

`
export default Typography
