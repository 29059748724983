import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
:root {
    --veryDark: #0B0C10;
    --dark: #1f2833;
    --darker-grey: #676a6c;
    --grey: #c5c7c8;
    --turquoise: #66fcf1;
    --darkTurquoise: #45a29e;

    --lime: #35db19;
    --green-leaf: #2a720c;
    --dark-green: #1b4908;

    /* --dark: #303c6c;
    --orange: #f4976c;
    --yellow: #fbe8a6;
    --dark-grey: #b4dfe5;
    --light-grey: #d2fdff; */
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background-color: var(--veryDark);
    color: var(--grey);
    scroll-behavior: smooth;
    line-height: 1.3;
    
}

li {
    list-style: none;
}

img {
    width: 100%;
}

h1 {
    margin: 0;
}

`
export default GlobalStyles
