import React from 'react'
import styled from 'styled-components'
import { FaGitlab, FaLinkedinIn, FaHeart } from 'react-icons/fa'
import { Link } from 'gatsby'
import { SocialIconsStyled, ButtonStyles } from '../styles/GenericStyles'

const FooterStyles = styled.footer`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1100px) minmax(0, 1fr);
  grid-area: footer;
  padding: 1em 0;
  box-sizing: border-box;

  .container {
    grid-column: 2 / 3;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr;
    text-align: center;
    margin: 0 1em 0em 1em;
    justify-content: center;
    align-items: center;
    p {
      font-size: 0.8rem;
    }

    .red {
      color: red;
    }

    .link:hover {
      transform: rotate(7deg);
    }
  }

  @media (min-width: 768px) {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`

const StyledLink = styled(Link)`
  ${ButtonStyles}
  display: inline-block;
`

function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <div>
          <StyledLink to="/#contact">Contact</StyledLink>
        </div>
        <SocialIconsStyled>
          <li>
            <a
              href="https://gitlab.com/ELeonhardt"
              target="_blank"
              rel="noreferrer"
            >
              <FaGitlab />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/elisabeth-l/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </li>
        </SocialIconsStyled>
        <p>
          coded with <FaHeart className="red" /> in Córdoba, Argentina
        </p>
      </div>
    </FooterStyles>
  )
}

export default Footer
