import styled, { css } from 'styled-components'

export const SocialIconsStyled = styled.ul`
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  li {
    padding: 0 1em;

    a {
      color: var(--lime);
    }
  }

  li:hover {
    transform: scale(1.3);
    transition: transform 200ms ease-in-out;
  }
`

// defining a mixin for button styles
export const ButtonStyles = css`
  border: 1px solid var(--lime);
  display: inline-block;
  border-radius: 0.4em;
  background: inherit;
  padding: 0.6em 0.8em;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey);
  transition: transform 500ms;
  &:hover {
    transform: ${props => `${props.deg || 'rotate(7deg)'}`};
    transition: transform 500ms;
  }
`
export const ButtonComponentStyles = styled.button`
  ${ButtonStyles}
`

export const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1100px) minmax(0, 1fr);
  .container {
    grid-column: 2 / 3;
  }

  @media (max-width: 1150px) {
    margin: 0 2em;
  }
`
