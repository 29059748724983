import React from 'react'
import styled from 'styled-components'
import 'normalize.css'
import PropTypes from 'prop-types'
import Typography from '../styles/Typography'
import GlobalStyles from '../styles/GlobalStyles'
import Header from './Header'
import Footer from './Footer'

const ContentWrapperStyles = styled.div`
  min-height: 100vh;
  overflow: auto;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
`

function Layout({ children }) {
  return (
    <>
      <Typography />
      <GlobalStyles />
      <ContentWrapperStyles>
        <Header />
        {children}
        <Footer />
      </ContentWrapperStyles>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.object,
}

export default Layout
