import React, { useState } from 'react'
import styled from 'styled-components'
import { FaGitlab, FaLinkedinIn, FaHamburger } from 'react-icons/fa'
import { Link } from 'gatsby'

import { SocialIconsStyled } from '../styles/GenericStyles'

const HeaderStyles = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1100px) minmax(0, 1fr);
  min-height: 8vh;
  margin: 0 2em;

  .container {
    grid-column: 2 / 3;
    position: relative;
    padding: 1em 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nav {
    ul {
      display: flex;
    }

    li {
      padding: 0 1em;
    }

    a {
      text-decoration: none;
      color: var(--grey);
      cursor: pointer;
    }

    a:hover {
      color: var(--lime);
    }
  }

  .hamburger {
    display: none;
  }

  @media (max-width: 768px) {
    .hamburger {
      display: block;
      border: none;
      background: var(--very-dark);
      color: var(--lime);
      font-size: 1.5em;
      padding: 0 1em;
      cursor: pointer;
    }

    .hamburger:hover {
      scale: 1.3;
    }

    nav {
      transform: scale(0, 1);
      transition: transform 500ms ease-in-out;
      transform-origin: right;
      position: absolute;
      top: 100%;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 92vh;
      z-index: 15;
      ul {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        font-size: 2rem;
        font-weight: bold;
        opacity: 0;
      }

      li {
        padding-bottom: 1em;
      }
    }

    .visible {
      transform: scale(1, 1);
      ul {
        opacity: 1;
        transition: opacity 400ms ease-in-out 400ms;
      }
    }
  }
`
function Header() {
  const [nav, setNav] = useState(false)

  return (
    <HeaderStyles>
      <div className="container">
        <SocialIconsStyled>
          <li>
            <a
              href="https://gitlab.com/ELeonhardt"
              target="_blank"
              rel="noreferrer"
            >
              <FaGitlab />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/elisabeth-l/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </li>
        </SocialIconsStyled>
        <nav className={nav ? 'visible' : ''}>
          <ul>
            <li>
              <Link to="/" onClick={() => setNav(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/#projects" onClick={() => setNav(false)}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/#about" onClick={() => setNav(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/#contact" onClick={() => setNav(false)}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          className="hamburger"
          onClick={() => setNav(!nav)}
        >
          <FaHamburger />
        </button>
      </div>
    </HeaderStyles>
  )
}

export default Header
